import React, {
  FocusEvent,
  Fragment,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react'

import { AutocompleteConfig } from '@components/LocationAutocomplete'
import useArrowControls from '@hooks/useArrowControls'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import utils from '@lib/utils'
import { Input, Modal, Option, Tooltip } from '@ui'
import Options from '@ui/Autocomplete/Options'
import { InputProps } from '@ui/Input'

import '@ui/Autocomplete/index.scss'

interface AutocompleteProps<T> {
  value: T | null
  onSelect: (value: T, position: number) => void
  inputValue: string
  onInputChange: (value: string) => void
  options: T[]
  renderOption: (option: T) => ReactNode
  getOptionChildren: (option: T) => T[]
  onInputFocus?: (e: FocusEvent) => void
  onInputBlur?: (e: FocusEvent) => void
  placeholder?: string | null
  inputErrorMessage?: string | null
  inputDisabled?: boolean
  emptyOptionMessage?: string | null
  config?: {
    default?: AutocompleteConfig
    modal?: AutocompleteConfig
  }
  renderListTitle?: (option: T) => ReactNode
}

const Autocomplete = <Item,>({
  value,
  inputValue,
  options,
  renderOption,
  getOptionChildren,
  onInputChange,
  onSelect,
  onInputFocus,
  onInputBlur,
  inputErrorMessage,
  emptyOptionMessage,
  inputDisabled,
  config,
  renderListTitle,
}: AutocompleteProps<Item>): ReactElement => {
  const [opened, setOpened] = useState(false)
  const id = useId()
  const scroll = useRef(0)
  const isMobile = useIsMobile()

  const allOptions = useMemo(() => utils.array.flatten(options, getOptionChildren), [getOptionChildren, options])

  const handleScroll = useCallback((top: number): void => {
    window.scrollTo({ behavior: 'instant', top })
  }, [])

  const handleToggle = useCallback(
    (state: boolean): void => {
      setOpened(state)

      if (!isMobile) return
      if (state) scroll.current = window.scrollY
      // workaround for scrolling issue on mobile devices
      state && handleScroll(0)
      !state && handleScroll(scroll.current)
    },
    [handleScroll, isMobile],
  )

  const handleSelect = (option: Item, event?: MouseEvent): void => {
    event?.stopPropagation()
    const position = allOptions.indexOf(option) + 1
    onSelect(option, position)
    handleToggle(false)
  }

  const { onKeyDown, activeItem, resetActiveItem } = useArrowControls(allOptions, handleSelect, value)

  const handleKeyDown = (e: KeyboardEvent): void => {
    /* istanbul ignore next: cypress doesn't support .type({tab}) command */
    if (e.key === 'Tab') setOpened(false)
    onKeyDown(e)
  }

  const renderOptions = (list: Item[], level: number = 0): ReactNode => {
    return list.map((option, index) => (
      <Fragment key={String(id) + String(index)}>
        {renderListTitle?.(option)}
        <Option
          value={option}
          onSelect={handleSelect}
          selected={option === value}
          active={option === activeItem}
          offset={level ? level * 45 : 16}
          data-tag="autocomplete-option"
        >
          {renderOption(option)}
        </Option>
        {renderOptions(getOptionChildren(option), level + 1)}
      </Fragment>
    ))
  }

  const listClassNames = bem('ui-autocomplete', 'list', { opened })

  useEffect(() => {
    if (!opened) {
      resetActiveItem()
    }
  }, [opened, resetActiveItem])

  const getInput = (props?: Partial<InputProps>) => (
    <Input
      value={inputValue}
      onChange={onInputChange}
      errorMessage={inputErrorMessage}
      onFocus={onInputFocus}
      onBlur={onInputBlur}
      disabled={inputDisabled}
      onKeyDown={handleKeyDown}
      trim={false}
      resettable
      {...props}
    />
  )
  const renderedOptions = (
    <Options options={renderOptions(options)} listClassNames={listClassNames} errorMessage={emptyOptionMessage} />
  )

  if (isMobile) {
    return (
      <>
        <div className="ui-autocomplete" onClick={() => handleToggle(true)}>
          <div className="ui-autocomplete__main-input">{getInput({ ...config?.default })}</div>
        </div>
        <Modal
          opened={opened}
          className={bem('ui-autocomplete__modal', { opened })}
          fullScreen
          header={getInput({ ...config?.modal, label: null, autoFocus: true })}
          title={config?.modal?.label}
          onClose={e => {
            e?.stopPropagation()
            handleToggle(false)
          }}
        >
          {renderedOptions}
        </Modal>
      </>
    )
  }

  return (
    <>
      <Tooltip
        action="focus"
        content={renderedOptions}
        disabled={isMobile}
        position="bottom-start"
        opened={opened}
        onOpenChanged={setOpened}
        anchorWidth
        maxHeight="md"
        maxWidth="md"
        popperClassName={bem('ui-autocomplete', 'tooltip')}
      >
        <div className="ui-autocomplete">{getInput({ ...config?.default })}</div>
      </Tooltip>
    </>
  )
}

export default Autocomplete
