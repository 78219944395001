import { Campaign } from '@amplitude/analytics-types/lib/esm/campaign'
import React, { ReactElement, useEffect, useMemo } from 'react'

import ErrorBoundary from '@components/ErrorBoundary'
import ErrorPage from '@components/ErrorPage'
import Initializer from '@components/Initializer'
import Loader from '@components/Loader'
import useSearchBarSettings from '@components/SearchBar/useSearchBarSettings'
import SearchForm, { SearchFormData } from '@components/SearchForm'
import config from '@config'
import { AppContextProvider, useAppContext } from '@context/app'
import useIsMobile from '@hooks/useIsMobile'
import analytics from '@lib/analytics'
import amplitude from '@lib/analytics/amplitude'
import assert from '@lib/assert'
import currencyUtils from '@lib/currency'
import date from '@lib/date'
import paramsUtils from '@lib/params'
import { QueryClientProvider } from '@lib/queryClient'
import sdk from '@lib/sdk'
import style from '@lib/style'
import url from '@lib/url'
import { useAnalyticsSettings } from '@loaders/analyticsSettings'
import { useLocationName } from '@loaders/locations'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import '../../index.scss'

interface SDKSearchProps {
  locale: Locale
  partnerNumber: number
  target: SDK.Target
  layout?: Direction
  currency?: Currency
  carrierCodes?: string[] // is used to filter out suggestion results
  defaults?: {
    departureStation?: string
    departureArea?: string
    departureCity?: string
    arrivalStation?: string
    arrivalArea?: string
    arrivalCity?: string
  }
  utm?: Campaign
}

const ALLOWED_LAYOUT_VALUES = ['row', 'column']
const DEFAULT_LAYOUT = 'column'

const Search = (props: SDKSearchProps): ReactElement => {
  const { locale, currency: configCurrency, defaults, carrierCodes, target, utm } = props
  const [, setParams] = useParams()
  const { retailerPartnerNumber } = useAppContext()
  const [{ colors, currency: settingsCurrency }, { isFetching }] = useSettings()

  const isMobile = useIsMobile()
  const layout = useMemo(() => {
    if (isMobile) return DEFAULT_LAYOUT

    return props.layout ?? DEFAULT_LAYOUT
  }, [isMobile, props.layout])

  assert.positive(retailerPartnerNumber, '`partnerNumber` parameter is invalid')
  assert.includes(
    layout,
    ALLOWED_LAYOUT_VALUES,
    `only ${ALLOWED_LAYOUT_VALUES.map(x => `'${x}'`).join(', ')} are valid values for layout`,
  )

  const searchResultUrl = config.resolve.searchResultUrl[retailerPartnerNumber] ?? config.searchResultUrl
  const currency = useMemo(
    () => configCurrency ?? currencyUtils.getBestCurrency(settingsCurrency.supported, settingsCurrency.default),
    [configCurrency, settingsCurrency],
  )

  const departureLocationData = paramsUtils.unflatLocation({
    departureStation: defaults?.departureStation,
    departureArea: defaults?.departureArea,
    departureCity: defaults?.departureCity,
  })

  const arrivalLocationData = paramsUtils.unflatLocation({
    arrivalStation: defaults?.arrivalStation,
    arrivalArea: defaults?.arrivalArea,
    arrivalCity: defaults?.arrivalCity,
  })

  const { data: departureLocation } = useLocationName({ locale, location: departureLocationData })
  const { data: arrivalLocation } = useLocationName({ locale, location: arrivalLocationData })

  useEffect(() => {
    setParams({
      retailerPartnerNumber,
      locale,
      currency,
      marketingCarrierCode: carrierCodes?.[0],
    })
  }, [carrierCodes, currency, locale, retailerPartnerNumber, setParams, settingsCurrency])

  useAnalyticsSettings(
    { retailerPartnerNumber },
    {
      onSuccess: data => {
        analytics.init(data, { only: ['amplitude'] })
        amplitude.viewWidget()
      },
    },
  )

  useEffect(() => style.applyCustomColors(colors), [colors])

  const initialValues = {
    arrivalLocation,
    departureLocation,
    departureDate: date.formatDate(new Date()),
    returnDate: null,
    departureTime: null,
    departureEndTime: null,
    pax: 1,
    passengers: null,
    cards: [],
  }

  const handleSubmit = ({ arrivalLocation, departureLocation, ...params }: SearchFormData): void => {
    const uri = url.build([searchResultUrl], {
      ...params,
      ...utm,
      ...(departureLocation && paramsUtils.flatLocation(departureLocation, 'departure')),
      ...(arrivalLocation && paramsUtils.flatLocation(arrivalLocation, 'arrival')),
      currency,
      locale,
      retailerPartnerNumber,
      deviceId: amplitude.getDeviceId(),
    })

    sdk.redirect(uri, target)
  }

  const searchSettings = useSearchBarSettings()

  return (
    <Loader loading={isFetching}>
      <div className="p-4">
        <SearchForm
          direction={layout}
          settings={searchSettings}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      </div>
    </Loader>
  )
}

const App = (props: SDKSearchProps): ReactElement => {
  const initializerParams = { retailerPartnerNumber: props.partnerNumber > 0 ? props.partnerNumber : undefined }

  return (
    <ErrorBoundary fallback={({ error }) => <ErrorPage error={error} />}>
      <AppContextProvider retailerPartnerNumber={props.partnerNumber} marketingCarrierCode={props.carrierCodes?.[0]}>
        <QueryClientProvider>
          <Initializer locale={props.locale} params={initializerParams}>
            <Search {...props} />
          </Initializer>
        </QueryClientProvider>
      </AppContextProvider>
    </ErrorBoundary>
  )
}

export default App
