import currency, { Options } from 'currency.js'

import config from '@config'
import timezoneCurrencyMap from '@lib/timezonCurrencyMap'
import utils from '@lib/utils'
import exchangeRatesStore from '@stores/exchangeRates'

type CurrencyFormatMap = Record<
  string,
  {
    symbol: string
    name: string
    multiplier: number
    decimal?: string
    precision?: number
    separator?: string
  }
>

const MAP: CurrencyFormatMap = {
  ALL: { symbol: 'L', name: 'ALL', multiplier: 1 },
  AED: { symbol: 'د.إ', name: 'AED', multiplier: 1, decimal: '.' },
  AUD: { symbol: 'AU$', name: 'AUD', multiplier: 1, decimal: '.' },
  BAM: { symbol: 'KM', name: 'BAM', multiplier: 1 },
  BGN: { symbol: 'ЛВ.', name: 'BGN', multiplier: 1 },
  BRL: { symbol: 'R$', name: 'BRL', multiplier: 1 },
  CAD: { symbol: 'C$', name: 'CAD', multiplier: 1 },
  CHF: { symbol: 'CHF', name: 'CHF', multiplier: 1 },
  CNY: { symbol: '¥', name: 'CNY', multiplier: 1, decimal: '.' },
  CZK: { symbol: 'Kč', name: 'CZK', multiplier: 1 },
  DKK: { symbol: 'kr.', name: 'DKK', multiplier: 1 },
  EUR: { symbol: '€', name: 'EUR', multiplier: 1 },
  GBP: { symbol: '£', name: 'GBP', multiplier: 1, decimal: '.' },
  HRK: { symbol: 'kn', name: 'HRK', multiplier: 1 },
  HUF: { symbol: 'Ft', name: 'HUF', multiplier: 100, decimal: '.' },
  IDR: { symbol: 'Rp', name: 'IDR', multiplier: 1, precision: 2, separator: ',', decimal: '.' },
  INR: { symbol: '₹', name: 'INR', multiplier: 1 },
  KES: { symbol: 'KES', name: 'KES', multiplier: 1, decimal: '.' },
  MKD: { symbol: 'ден', name: 'MKD', multiplier: 1 },
  MXN: { symbol: 'MX$', name: 'MXN', multiplier: 1, decimal: '.' },
  NGN: { symbol: '₦', name: 'NGN', multiplier: 1, decimal: '.' },
  NOK: { symbol: 'kr', name: 'NOK', multiplier: 1 },
  PHP: { symbol: '₱', name: 'PHP', multiplier: 1, decimal: '.' },
  PKR: { symbol: 'Rs', name: 'PKR', multiplier: 1, decimal: '.' },
  PLN: { symbol: 'zł', name: 'PLN', multiplier: 1 },
  RON: { symbol: 'L', name: 'RON', multiplier: 1 },
  RSD: { symbol: 'дин.', name: 'RSD', multiplier: 1 },
  RUB: { symbol: '₽', name: 'RUB', multiplier: 1 },
  SEK: { symbol: 'kr', name: 'SEK', multiplier: 1 },
  SGD: { symbol: 'S$', name: 'SGD', multiplier: 1, decimal: '.' },
  TRY: { symbol: '₺', name: 'TRY', multiplier: 1 },
  THB: { symbol: '฿', name: 'THB', multiplier: 1, decimal: '.' },
  TWD: { symbol: '元', name: 'TWD', multiplier: 1, decimal: '.' },
  UAH: { symbol: '₴', name: 'UAH', multiplier: 1 },
  USD: { symbol: 'US$', name: 'USD', multiplier: 1, decimal: '.' },
  VND: { symbol: '₫', name: 'VND', multiplier: 1, precision: 0, separator: '.' },
  COP: { symbol: 'CO$', name: 'COP', multiplier: 1, decimal: '.' },
  JPY: { symbol: '¥', name: 'JPY', multiplier: 1, precision: 0 },
}

const getSeparator = (currency: Currency): string => {
  const code = currency.toUpperCase()

  return MAP[code]?.separator ?? ','
}

const getSymbol = (currency: Currency): string => {
  const code = currency.toUpperCase()

  return MAP[code]?.symbol ?? code
}

const getName = (currency: Currency): string | null => {
  const code = currency.toUpperCase()

  return MAP[code]?.name
}

const getDecimal = (currency: Currency): string => {
  const code = currency.toUpperCase()

  return MAP[code]?.decimal ?? ','
}

const getMultiplier = (currency: Currency): number => {
  const code = currency.toUpperCase()

  return MAP[code].multiplier
}

const getPrecision = (currency: Currency): number => {
  const code = currency.toUpperCase()

  return MAP[code]?.precision ?? 2
}

const sort = (supported: Currency[]): Currency[] => {
  const filteredTop = config.settings.topCurrencies.filter(item => supported.includes(item))
  const restCurrencies = supported.filter(item => !filteredTop.includes(item)).sort((a, b) => (a > b ? 1 : -1))

  return [...filteredTop, ...restCurrencies]
}

const isTopLast = (supported: Currency[], code: Currency): boolean => {
  const filteredTop = config.settings.topCurrencies.filter(item => supported.includes(item))

  return filteredTop.at(-1) === code
}

export const createCurrency = (price: number, code?: Currency, opt?: Options): currency => {
  const currencySign = code ? getSymbol(code) : ''
  const currencyDecimal = code ? getDecimal(code) : ','
  const multiplier = code ? getMultiplier(code) : 1
  const selectedPrecision = code ? getPrecision(code) : 2
  const separator = code ? getSeparator(code) : ','
  const options = {
    fromCents: true,
    separator: separator,
    decimal: currencyDecimal,
    symbol: currencySign,
    precision: selectedPrecision,
    ...opt,
  }

  return currency(price, options).multiply(multiplier)
}

const getExchangeRate = (currency: Currency): number | undefined =>
  exchangeRatesStore.get().rates.find(({ code }) => code === currency)?.exchangeRate

const formatPrice = (fractional: number): number => createCurrency(fractional).value

const getPriceInEuro = (price: Money): number => {
  const exchangeRate = getExchangeRate(price.currency)
  const fractional = Math.round(price.fractional / Number(exchangeRate))

  return formatPrice(fractional)
}

const getBestCurrency = (supported: Currency[], defaultValue: Currency): Currency => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const countryCurrency = timezoneCurrencyMap[timezone]

  return utils.array.containsOrDefault(countryCurrency, supported, defaultValue)
}

const currencyUtils = {
  getSymbol,
  getName,
  sort,
  isTopLast,
  create: createCurrency,
  getPriceInEuro,
  formatPrice,
  getBestCurrency,
  getMultiplier,
  getPrecision,
  getDecimal,
}

export default currencyUtils
