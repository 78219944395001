import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'

import utils from '@lib/utils'

type LocaleNamesMap = Record<Locale, string>

const NAMES: LocaleNamesMap = {
  bg: 'Български',
  cs: 'Czech',
  da: 'Dansk',
  de: 'Deutsch',
  en: 'English (GB)',
  'en-IE': 'English (IE)',
  'en-US': 'English (USA)',
  'en-CA': 'English (CAN)',
  es: 'Español',
  'es-CO': 'Español (CO)',
  'es-EC': 'Español (EC)',
  'es-MX': 'Español (MX)',
  fr: 'Français',
  'hi-IN': 'हिन्दी (IN)',
  hr: 'Hrvatski',
  it: 'Italiano',
  nl: 'Nederlands',
  pl: 'Polski',
  'pt-BR': 'Português (BR)',
  'sk-SK': 'Slovak',
  pt: 'Português',
  sv: 'Svenska',
  sq: 'Shqip',
  tr: 'Türkçe',
  zh: '中文',
  ar: 'العربية',
  ja: '日本語',
  no: 'Norsk',
  ur: 'اردو',
  vi: 'Tiếng việt',
  bs: 'Bosanski',
  el: 'Ελληνική',
  hu: 'Magyar',
  mk: 'Македонски',
  ro: 'Română',
  ru: 'Русский',
  sr: 'Srpski',
  uk: 'Українська',
  'th-TH': 'ไทย',
}

const getCode = (locale: Locale): Locale => {
  const [code] = locale.split('-')

  return code.toLowerCase() as Locale
}

const getName = (locale: Locale): string => {
  const code = getCode(locale)

  return NAMES[locale] ?? NAMES[code] ?? getCode(locale)
}

const getFlagImage = (locale: Locale): string => {
  try {
    return require(`@images/flags/${locale}.svg`)
  } catch {
    const code = getCode(locale)

    if (code !== locale) return getFlagImage(code)

    return ''
  }
}

const languageDetector = new I18nextBrowserLanguageDetector(
  // We need this to receive all detected locales instead of only the first one
  { languageUtils: { getBestMatchFromCodes: true } },
  {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'locale',
  },
)

const getSupportedVariant = (locale: string | undefined, supported: Locale[]): Locale | null =>
  utils.array.containsOrDefault(
    locale,
    supported,
    utils.array.containsOrDefault(locale?.split('-')[0], supported, null),
  ) as Locale | null

const getPreferred = (supported: Locale[]): Locale | null | undefined => {
  const preferred = languageDetector.detect()

  /* istanbul ignore next */
  if (!Array.isArray(preferred)) return getSupportedVariant(preferred, supported)

  for (const language of preferred) {
    const supportedLanguage = getSupportedVariant(language, supported)

    if (supportedLanguage != null) return supportedLanguage
  }

  return null
}

export default {
  getName,
  getCode,
  getFlagImage,
  getPreferred,
}
