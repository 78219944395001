import { PassengersTypesResponse } from '@api/passengerTypes'
import { PassengersTypeParams } from '@loaders/connections'
import { PassengerData } from '@stores/checkout'

const MULTIPLE_CARRIERS_PASSENGER_TYPE = [
  { name: 'adult', code: 'PNOS', minAge: 30, maxAge: 59 },
  { name: 'youth', code: 'PYPO', minAge: 0, maxAge: 29 },
  { name: 'senior', code: 'PSOE', minAge: 60, maxAge: 99 },
]

const MIN_PASSENGER_COUNT: Record<string, number> = {
  PNOS: 1,
  passengers: 1,
  default: 0,
}

const PASSENGER_PARAM: Record<string, Passenger.Param> = {
  PNOS: { maxAge: 65, pax: 1, type: 'PNOS', cards: [] },
}

export const PASSENGERS_CODE = {
  infant: 'PINT',
  adult: 'PNOS',
  child: 'PCIL',
  youth: 'PYPO',
  senior: 'PSOE',
}

const buildTypesMap = (types: Passenger.Type[]): Record<string, Passenger.Type> => {
  return types.reduce<Record<string, Passenger.Type>>(
    (mem, item) => ({
      ...mem,
      [item.code]: item,
    }),
    {},
  )
}

const getInitialAdultType = (supported: Passenger.Type[]): Passenger.Param => {
  const adultType = supported.filter(({ code }) => code === PASSENGERS_CODE.adult)[0]

  return { type: adultType.code, pax: 1, maxAge: adultType.maxAge, cards: [] }
}

const getMinCount = (type: string): number => MIN_PASSENGER_COUNT[type] ?? MIN_PASSENGER_COUNT.default

const typesToParams = (types: Passenger.Type[]): Passenger.Param[] =>
  types.map(p => ({ type: p.code, pax: getMinCount(p.code), maxAge: p.maxAge, cards: [] }))

const getInitialPassengers = (supported: Passenger.Type[], passengers: Passenger.Param[] | null): Passenger.Param[] => {
  const initialPassenger = getInitialAdultType(supported)
  const supportedTypes = supported.map(({ code }) => code)
  const list = passengers?.filter(({ type }) => supportedTypes.includes(type))

  return list && list.length > 0 ? list : [initialPassenger]
}

const isInsideAge = ({ minAge, maxAge }: Passenger.Type, age: number): boolean => age >= minAge && age <= maxAge
const getTypeByAge = (types: Passenger.Type[], age: number): Passenger.Type | undefined =>
  types.find(type => isInsideAge(type, age))

const getPaxCount = (passengers: Passenger.Param[] | null): number =>
  passengers?.reduce((acc, { pax }) => acc + Number(pax), 0) ?? 1

const getAgeRange = (supported: Passenger.Type[], type: string, pax: number): PassengersTypeParams => {
  /* istanbul ignore next */
  const { maxAge = PASSENGER_PARAM.PNOS.maxAge as number } =
    supported.find(p => p.code === type) ?? /* istanbul ignore next */ {}

  return { maxAge, pax }
}

const getPassengerTypeParams = (passengers: Passenger.Param[], supported: Passenger.Type[]): PassengersTypeParams[] =>
  passengers.reduce<PassengersTypeParams[]>((acc, { maxAge, cards, pax, type, ...rest }) => {
    const ageRange = getAgeRange(supported, type, pax)
    const selected = acc.find(p => Number(p.maxAge) === Number(maxAge) && !p.cards?.length)

    if (cards?.length) return [...acc, { maxAge: maxAge ?? ageRange.maxAge, pax, cards, ...rest }]
    if (!selected) return [...acc, { maxAge: maxAge ?? ageRange.maxAge, pax, cards, ...rest }]

    selected.pax += 1

    return acc
  }, [])

const buildPassengerTypes = (passengers: Passenger.Param[], supported: Passenger.Type[]): Passenger.Param[] =>
  getPassengerTypeParams(passengers, supported).map(item => ({
    type: getTypeByAge(supported, item.maxAge)?.code as string,
    pax: item.pax,
    cards: item.cards,
    maxAge: item.maxAge,
    firstName: item.firstName,
    lastName: item.lastName,
  }))

const getPassengerCode = (type: (keyof typeof PASSENGERS_CODE)[]): string[] => type.map(item => PASSENGERS_CODE[item])

const getPaxCards = (cards?: DiscountCode.Card[] | undefined): DiscountCode.Card[] | undefined =>
  cards?.map(({ name, code }) => ({ name, code }))

const filterPassengers = (passengers: PassengerData[]): PassengerData[] =>
  passengers.filter(({ type, pax }) => type && pax)

const getPassengerList = (passengers: PassengerData[]): Passenger.Param[] =>
  passengers.reduce<Passenger.Param[]>((acc, { type, pax, cards }) => {
    const obj = acc.find(p => p.type === type)

    if (!obj) return [...acc, { type, pax, cards }]

    obj.pax += 1

    return acc
  }, [])

const sortPassengers = (passengers: Passenger.Type[]): Passenger.Type[] =>
  passengers.sort((a, b) => {
    if (b.code === PASSENGERS_CODE['adult']) return 1

    return b.maxAge - a.maxAge
  })

const getMultipleCarriersPaxList = (): PassengersTypesResponse => MULTIPLE_CARRIERS_PASSENGER_TYPE

const getDefaultPassenger = (): Passenger.Param => PASSENGER_PARAM['PNOS']

const getBookingPassengers = (passengers: PassengerData[]): PassengerData[] => {
  const excludeKeys = ['id', 'maxAge', 'minAge']

  return passengers.reduce<PassengerData[]>((acc, curr) => {
    const entries = Object.entries(curr).filter(([key, value]) => !excludeKeys.includes(key) && Boolean(value))

    return [...acc, Object.fromEntries(entries) as PassengerData]
  }, [])
}

const passengerUtils = {
  buildTypesMap,
  getMinCount,
  typesToParams,
  getInitialPassengers,
  getPaxCount,
  getPassengerTypeParams,
  getAgeRange,
  getPassengerCode,
  filterPassengers,
  getPassengerList,
  sortPassengers,
  getTypeByAge,
  buildPassengerTypes,
  isInsideAge,
  getPaxCards,
  getMultipleCarriersPaxList,
  getDefaultPassenger,
  getBookingPassengers,
}

export default passengerUtils
