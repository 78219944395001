import { CarrierConfigResponse } from '@api/carrier'
import { SettingsResponse } from '@api/settings'
import config from '@config'
import { createStore } from '@lib/store'
import { initialColors } from '@lib/style'
import { TicketsAction } from '@queries/settings/enum'

type SettingsStore = SettingsResponse & CarrierConfigResponse

const {
  fallback: { currency, locale },
} = config

const initialValues: SettingsStore = {
  discountVoucher: { enabled: false },
  backgroundImage: { enabled: false, url: null, headerOffset: false },
  boardingByQrCode: { enabled: false },
  bookedOut: { enabled: false },
  checkout: { type: 'old' },
  colors: initialColors,
  currency: { supported: currency.supported, default: currency.default },
  fareClasses: { displayOn: 'nowhere', supported: [], split: false },
  filter: { enabled: false, supported: [] },
  googleWallet: { enabled: false },
  governmentId: { enabled: false, trips: 'all', types: [], default: null, fields: [] },
  locale: { supported: locale.supported, default: locale.default },
  logo: { url: '', redirectionUrl: null },
  passengerType: { enabled: false },
  phone: { enabled: false, optional: false },
  returnTrip: { enabled: false },
  searchBar: { layout: 'column' },
  searchResultUrl: { url: config.searchResultUrl },
  seatSelection: { enabledOnSearchResults: false, enabledOnCheckout: false },
  sorting: { enabled: false, supported: [], default: null },
  title: { text: '', favicon: '' },
  tripTime: { enabled: false },
  cpf: { enabled: false },
  paymentProvider: { code: 'adyen', options: {} },
  paymentMethods: {
    pix: false,
    applePay: false,
    googlePay: false,
    paypal: false,
    creditCard: false,
    cash: false,
    terminal: false,
    invoice: false,
    blik: false,
    storedPayment: false,
  },
  reservation: { enabled: false },
  birthday: { enabled: false },
  ancillaries: { enabled: false },
  passengerTypesList: { enabled: false, carrier: null, multiple: false },
  quickReservation: { enabled: false },
  discountCodes: { enabled: false, displayOn: 'everywhere' },
  amtrackSavedCards: { enabled: false, cvc: false },
  paxDiscountCodes: { enabled: false, displayOn: 'everywhere' },
  billingAddress: { enabled: false },
  carrierLogo: { enabled: true, source: 'marketing_carrier' },
  holdBooking: { enabled: false },
  downloadTicket: { enabled: false, action: TicketsAction.DOWNLOAD },
  marketingMaterials: { enabled: false },
  searchCalendar: { enabled: true, displayPrice: 'all_days' },
  vehicleIdentifier: { enabled: false },
  email: { enabled: true, optional: false, verification: false },
  endpoints: {
    newBackend: [],
  },
  whatsappButton: {
    enabled: false,
    phoneNumber: null,
  },
  gender: {
    enabled: false,
    trips: 'all',
  },
  customElements: {
    enabled: false,
    pages: [],
  },
  manageBookings: { enabled: false, backgroundUrl: null },
  nethone: { enabled: false },
  trackTrip: { enabled: false },
  menu: { items: [] },
  priceCalendar: { enabled: false },
  accommodation: { enabled: false, partner: 'expedia' },
  footer: { menu: [], socials: [] },
  support: { url: null },
  agentCounterPage: { departureLocation: null, arrivalLocation: null },
  customDomain: { enabled: false },
  transportationMode: { enabled: false, supported: [] },
  suggestions: { count: 10, display: ['station', 'area', 'city'] },
  media: { enabled: false, displayOn: 'everywhere', preview: false },
  reselling: { enabled: false, image: null, link: null },
  passengerName: { enabled: true },
  termsAndPrivacy: { enabled: true },
  seo: { enabled: true },
  distribusionBranding: {
    enabled: false,
    pages: [],
  },
  cookiesConsent: {
    enabled: false,
    provider: null,
  },
  changeCalculator: { enabled: false },
  priceHints: { enabled: false },
  abTest: { enabled: false, feature: null },
}
const store = createStore<SettingsStore>(initialValues)

export default store
