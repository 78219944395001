import config from '@config'
import { ArrayFormat } from '@lib/queryString'
import { toNestedObject } from '@lib/responseProcessing'
import urlUtils from '@lib/url'
import settingsStore from '@stores/settings'

type RequestValue = any

type RequestParams = Record<string, RequestValue | RequestValue[]>

export type EndpointType =
  | 'cancellations'
  | 'confirmation'
  | 'connections/vacancy'
  | 'discountCards'
  | 'exchange_rates'
  | 'locations'
  | 'marketingCarriers/find'
  | 'marketingCarriers/logo'
  | 'reservations'
  | 'seats'
  | 'tickets'
  | 'trips'

interface PathConfig {
  type: EndpointType
  old?: string | null
  new: string
}

interface ApiCollection extends ApiUrl {
  portalUrl: string
}

export const API_PATHNAME = 'api'
export const API_OLD_PATHNAME = 'new_search'
const PORTAL_PATHNAME = 'portal'

const SUGGESTION_API_ENDPOINTS = [/locations$/]

const NEW_API_ENDPOINTS = [
  /settings/,
  /countries/,
  /fare_classes/,
  /passenger_types/,
  /installment_rates/,
  /marketing_carriers\/([^/]+)$/,
  /connections\/cheapest_prices/,
  /connections\/vacancy/,
  /connections\/price_calendar/,
  /connections$/,
  /landing/,
  /popular_connections/,
  /conditions/,
  /stations/,
  /carrier/,
  /feedback/,
  /media/,
  /realtime\/trips(\/.*)?$/,
  /price_insights/,
]

const PORTAL_ENDPOINTS = [/retailer\/\d+\/cards$/, /\/retailer\/[^/]+\/billing_address/]
const ENDPOINTS_WITH_COOKIES = [
  /\/portal\/.*/,
  /\/new_search\/reservations/,
  /\/new_search\/bookings/,
  /\/new_search\/amendments/,
]

const HOSTS_WITH_COOKIES = [/localhost/, /\.distribusion\.com$/, /\.railagent/]
const HOSTS_PORTAL = [/^https:\/\/portal\.distribusion\.com(\/.*)?$/]
const HOSTS_RAILAGENT = [/^https:\/\/portal\.railagent\.com(\/.*)?$/]

type RequestMethod = 'GET' | 'POST' | 'PUT'

export interface RequestProps<Params, Body> {
  params: Params
  body: Body
}

type ApiErrorData = Record<string, string | Record<string, string>[]>

interface ApiErrorParams {
  code: ErrorCode
  data: ApiErrorData
  status: number
  path: string
}

export class ApiError extends Error {
  readonly code!: ErrorCode
  readonly data!: ApiErrorData
  readonly status!: number
  readonly path!: string

  constructor(params: ApiErrorParams) {
    super(params.code)

    Object.assign(this, params)
  }
}

const makeApiCollection = (collection: ApiUrl): ApiCollection => ({
  url: urlUtils.build([collection.url, API_PATHNAME]),
  oldUrl: urlUtils.build([collection.oldUrl, API_OLD_PATHNAME]),
  portalUrl: urlUtils.build([collection.url, PORTAL_PATHNAME]),
})

/* istanbul ignore next */
const getWhitelabelUrls = (): ApiUrl => {
  const { distribusion, railAgent, portal } = config.api
  const iframeDomain = new URLSearchParams(window.location.search).get('parentDomain')
  const domain = iframeDomain ?? window.location.origin

  if (HOSTS_PORTAL.some(r => r.test(domain))) return portal
  if (HOSTS_RAILAGENT.some(r => r.test(domain))) return railAgent

  return distribusion
}

const { url, oldUrl, portalUrl } = makeApiCollection(getWhitelabelUrls())

const getApiUrl = (path: string): string => {
  const { suggestionUrl } = config.api

  if (PORTAL_ENDPOINTS.some(r => r.test(path))) return portalUrl
  if (NEW_API_ENDPOINTS.some(r => r.test(path))) return url
  if (SUGGESTION_API_ENDPOINTS.some(regex => regex.test(path))) return suggestionUrl

  return oldUrl
}

const getEndpointParts = (path: string | PathConfig): string[] => {
  if (typeof path === 'string') return [getApiUrl(path), path]

  const {
    endpoints: { newBackend },
  } = settingsStore.get()

  return newBackend.includes(path.type) || !path.old ? [url, path.new] : [oldUrl, path.old]
}

export const buildUrl = (path: string | PathConfig, params: RequestParams, config: MakeApiConfig): string =>
  urlUtils.build(getEndpointParts(path), params, config.arrayFormat)

/* istanbul ignore next */
const hostWithCookies = HOSTS_WITH_COOKIES.some(r => r.test(window.location.hostname))

const shouldSendCookies = (url: string): boolean => {
  const { origin, pathname } = new URL(url)
  const uri = `${origin}${pathname}`

  /* istanbul ignore if */
  if (!hostWithCookies) return false

  return ENDPOINTS_WITH_COOKIES.some(regexp => regexp.test(uri))
}

// we can't use 'same-origin' because of sub-domains
const credentialsIncludeStrategy = (url: string): RequestCredentials => {
  if (shouldSendCookies(url)) return 'include'
  else return 'omit'
}

const processResponse = async <T, P extends RequestParams>(
  response: Response,
  config: MakeApiConfig,
  requestParams: P,
): Promise<T> => {
  const responseBody = await (async () => {
    try {
      return await response.json()
    } catch {
      return {}
    }
  })()

  if (response.ok) {
    const params = { ...apiConfigDefaults.responsePostprocessing, ...config.responsePostprocessing }
    const processedResponse = toNestedObject(responseBody, params) as T

    return config.transformResponse
      ? config.transformResponse(processedResponse, responseBody, requestParams)
      : processedResponse
  } else {
    throw new ApiError({
      code: responseBody.code ?? 'internalError',
      data: responseBody.data ?? {},
      status: response.status,
      path: response.url.replace(location.hostname, ''),
    })
  }
}

export interface ResponsePostprocessing {
  convertKeys?: 'LowerCamel' | 'UpperCamel' | 'SnakeCase'
  convertValues?: Record<string, 'Price' | 'DateTime'>
}

interface MakeApiConfig {
  arrayFormat?: ArrayFormat
  responsePostprocessing?: ResponsePostprocessing
  transformResponse?: <T, U, P extends RequestParams>(response: T, rawResponse: U, requestParams: P) => T
}

const apiConfigDefaults: MakeApiConfig = {
  arrayFormat: 'brackets',
  responsePostprocessing: {
    convertKeys: 'LowerCamel',
  },
}

const makeApi = <Params extends RequestParams, Body, ResponseBody>(
  method: RequestMethod,
  path: string | PathConfig,
  config: MakeApiConfig,
) => {
  return async ({ params, body }: RequestProps<Params, Body>) => {
    const url = buildUrl(path, params, config)
    const requestOptions = {
      method,
      body: body == null ? null : JSON.stringify(body),
      headers: body == null ? undefined : { 'Content-Type': 'application/json' },
      credentials: credentialsIncludeStrategy(url),
    }
    const response = await fetch(url, requestOptions)

    return await processResponse<ResponseBody, Params>(response, config, params)
  }
}

type GetFunction<Params, Response> = (params: Params) => Promise<Response>

const get = <Params extends RequestParams, Response>(
  path: string | PathConfig,
  config: MakeApiConfig = apiConfigDefaults,
): GetFunction<Params, Response> => {
  const callApi = makeApi<Params, null, Response>('GET', path, config)

  return async (params: Params): Promise<Response> => await callApi({ params, body: null })
}

type PostFunction<Params, Body, Response> = (props: RequestProps<Params, Body>) => Promise<Response>

const post = <Params extends RequestParams, Body, Response>(
  path: string | PathConfig,
  config: MakeApiConfig = apiConfigDefaults,
): PostFunction<Params, Body, Response> => makeApi<Params, Body, Response>('POST', path, config)

const put = <Params extends RequestParams, Body, Response>(
  path: string | PathConfig,
  config: MakeApiConfig = apiConfigDefaults,
): PostFunction<Params, Body, Response> => makeApi<Params, Body, Response>('PUT', path, config)

export default {
  get,
  post,
  put,
}
