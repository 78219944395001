import {
  flush as flushAmplitude,
  getDeviceId as getDeviceIdAmplitude,
  identify as identifyAmplitude,
  Identify,
  init as initAmplitude,
} from '@amplitude/analytics-browser'
import { parse as parseDomain } from 'tldts'

import config from '@config'
import checkout from '@lib/analytics/amplitude/checkout'
import common from '@lib/analytics/amplitude/common'
import confirmation from '@lib/analytics/amplitude/confirmation'
import error from '@lib/analytics/amplitude/error'
import helpers from '@lib/analytics/amplitude/helpers'
import landing from '@lib/analytics/amplitude/landing'
import results from '@lib/analytics/amplitude/results'
import suggestions from '@lib/analytics/amplitude/suggestions'
import paramsUtils from '@lib/params'

const { sendEvent } = helpers

interface ValueChangeEvent<T> {
  from: T
  to: T
}

// We need to manually identify the user for a new subdomain within the same domain
// https://distribusion.atlassian.net/browse/OWL-2935?focusedCommentId=187363
const isDifferentSubdomain = () => {
  const referrerUrl = parseDomain(document.referrer)
  const currentUrl = parseDomain(window.location.hostname)
  return referrerUrl.domain === currentUrl.domain && referrerUrl.subdomain !== currentUrl.subdomain
}

interface IdentifyData {
  set?: Record<string, string>
  append?: Record<string, string>
}
const identify = (data: IdentifyData): void => {
  const identifyEvent = new Identify()

  data.set && Object.entries(data.set).forEach(([key, value]) => identifyEvent.set(key, value))
  data.append && Object.entries(data.append).forEach(([key, value]) => identifyEvent.append(key, value))

  identifyAmplitude(identifyEvent)
}

const identifyUtm = () => {
  const utmParams = paramsUtils.getUtmParams(window.location.search)

  // istanbul ignore else
  if (Object.entries(utmParams).length > 0) {
    identify({ set: utmParams })
  }
}

const init = ({ container_id }: Analytics.IdConfig): void => {
  // istanbul ignore next
  if (!container_id) return

  if (isDifferentSubdomain()) {
    identifyUtm()
  }

  initAmplitude(container_id.toString(), undefined, {
    transport: 'beacon',
    flushIntervalMillis: config.env === 'test' ? 0 : /* istanbul ignore next */ 1000,
    defaultTracking: {
      attribution: true,
      pageViews: false,
      sessions: false,
      fileDownloads: false,
      formInteractions: false,
    },
  })

  addEventListener('beforeunload', () => {
    flushAmplitude()
  })
}
const getDeviceId = (): string | undefined => getDeviceIdAmplitude()
const changeLocale = (props: ValueChangeEvent<Locale>): void => {
  sendEvent('change-language', props)
}
const changeCurrency = (props: ValueChangeEvent<Currency>): void => {
  sendEvent('change-currency', props)
}

interface PopularDirectionProps {
  departureLocation: Location.NamedItem
  arrivalLocation: Location.NamedItem
  position: number
}

const clickPopularDirection = ({ departureLocation, arrivalLocation, position }: PopularDirectionProps): void => {
  sendEvent('click-popular-directions', {
    departure: { type: departureLocation.type, code: departureLocation.code },
    arrival: { type: arrivalLocation.type, code: arrivalLocation.code },
    route: `${departureLocation.name} - ${arrivalLocation.name}`,
    position,
  })
}

const viewSearchPage = (): void => {
  sendEvent('view-homepage', {}, { measurePerformance: 'page' })
}

const viewWidget = (): void => {
  sendEvent('view-widget', {}, { measurePerformance: 'widget' })
}

const clickDownloadTicket = (): void => {
  sendEvent('click-download-ticket')
}

const clickWhatsappButton = (): void => {
  sendEvent('whatsapp-click')
}

const onInternalRedirect = (): void => {
  performance.mark('redirect')
}

export default {
  init,
  changeLocale,
  changeCurrency,
  viewSearchPage,
  viewWidget,
  clickPopularDirection,
  landing,
  suggestions,
  checkout,
  confirmation,
  error,
  results,
  common,
  getDeviceId,
  clickDownloadTicket,
  onInternalRedirect,
  clickWhatsappButton,
  identify,
}
