import { isAfter, subHours } from 'date-fns'

import { BookingAncillaries } from '@api/booking'
import config from '@config'
import {
  categoryMappings,
  icons,
  PAX_UNLIMIT_CATEGORY,
  SEAT_CODE,
  OFFLOADING_CODE,
  MOTORCYCLE_CODE,
  SEGMENT_ANCILLARY,
  PAX_CATEGORY,
  RESERVATION_ANCILLARIES,
  PAX_CARRIER_ANCILLARY,
  SEPARATOR,
  STATIC_PRICE_ANCILLARIES,
} from '@lib/ancillary/mappings'
import checkout, { PassengerSeatInfo } from '@lib/checkout'
import dateUtils from '@lib/date'
import utils from '@lib/utils'
import { CheckoutFormData, Seats } from '@pages/Checkout/hooks/useInitialFormValues'
import { PassengerData } from '@stores/checkout'

export interface AncillariesInfo {
  price: Money
  name: string
  count: number
}

export type AncillariesFormData = Record<KnownAncillary, Ancillary.Item[]>

const getCategoryFromCode = (code: string): AncillaryCategory => {
  const upperCode = code.toUpperCase()

  const { category } = categoryMappings.find(({ codes }) => codes.includes(upperCode)) ?? { category: 'UNKNOWN' }

  return category
}

const getIcon = (code: AncillaryCategory): string | undefined => icons[code]

const getByCategory = (category: AncillaryCategory, ancillaries: Ancillary.Item[] = []): Ancillary.Item[] =>
  ancillaries.filter(item => item.category === category)

const getBookingAncillariesParams = (ancillaries: AncillariesFormData): BookingAncillaries[] =>
  Object.entries(ancillaries).flatMap(([_, value]) =>
    value
      .filter(({ level }) => level === 'booking')
      .map(({ price, initialPrice, code, segmentIndex }) => {
        const isReturnTrip = price.fractional / initialPrice?.fractional > 1
        const multiplier = Number(isReturnTrip) + 1
        const filtered = value.filter(a => a.code === code && a.segmentIndex === segmentIndex)

        return utils.object.compact<BookingAncillaries>({
          code,
          segmentIndex,
          price: initialPrice?.fractional ?? price.fractional,
          quantity: filtered.length * multiplier,
        })
      })
      .filter(
        (item: BookingAncillaries, index: number, self: BookingAncillaries[]) =>
          item.segmentIndex !== undefined || self.findIndex(s => s.code === item.code) === index,
      ),
  )

const buildSeatAncillary = (index: number, seats: Seats): PassengerSeatInfo[] =>
  checkout
    .getPassengerSeats(index, seats)
    .map(item => ({ ...item, quantity: 1, code: SEAT_CODE }))
    .filter(item => item.price > 0)

const getPaxAncillariesParams = (
  data: CheckoutFormData,
  index: number,
  seatAncillaries?: boolean,
): BookingAncillaries[] | null => {
  const ancillaries = Object.entries(data.ancillaries)
    .flatMap(([_, value]) =>
      value
        .filter(
          (item: Ancillary.Item) =>
            item.level === 'pax' &&
            Number(item.passengerIndex) - 1 === index &&
            !PAX_UNLIMIT_CATEGORY.includes(item.category),
        )
        .flatMap(({ code, initialPrice, price }: Ancillary.Item) => {
          const isOneWayTrip = price.fractional / initialPrice?.fractional === 1
          const ancillary = {
            code,
            price: initialPrice.fractional,
            quantity: 1,
            segmentIndex: 0,
          }

          return isOneWayTrip
            ? ancillary
            : new Array(2).fill(ancillary).map((item, segmentIndex) => ({ ...item, segmentIndex }))
        }),
    )
    .filter(item => item.code)

  const carbon =
    index === 0
      ? Object.entries(data.ancillaries).flatMap(([_, value]) =>
          value
            .filter(item => item.level === 'pax' && PAX_UNLIMIT_CATEGORY.includes(item.category))
            .reduce<BookingAncillaries[]>((mem, curr) => {
              const current = mem.find(item => item.code === curr.code)
              if (current) {
                current.quantity += 1

                return mem
              }

              return [...mem, { code: curr.code, price: curr.initialPrice.fractional, segmentIndex: 0, quantity: 1 }]
            }, []),
        )
      : []

  const totalAncillaries = [...ancillaries, ...carbon]
  if (seatAncillaries && data.seats) {
    // Temporarily solution for SEAT code
    totalAncillaries.push(...buildSeatAncillary(index, data.seats))
  }

  return totalAncillaries.length ? totalAncillaries : null
}

interface PriceInfoConfig {
  includeReservation?: boolean
}

//ToDo: We now have ancillary prices which are calculated on the server side or on the client side.
// With the current structure is has become difficult to keep track if the ancillary should be included in the price,
// so need to refactor this part in the future soon and remove the second function argument.
const getPriceInfo = (
  _ancillaries: Record<string, Ancillary.Item[]>,
  { includeReservation }: PriceInfoConfig,
): AncillariesInfo[] => {
  let ancillaries = _ancillaries
  if (!includeReservation) {
    ancillaries = Object.entries(ancillaries).reduce(
      (acc, [key, value]) => (RESERVATION_ANCILLARIES.includes(key) ? acc : { ...acc, [key]: value }),
      {},
    )
  }

  return Object.entries(ancillaries)
    .map(([key, value]) => ({
      name: key,
      count: value.length,
      price: value.reduce(
        (acc: Money, curr: Ancillary.Item) => {
          if (RESERVATION_ANCILLARIES.includes(key) && !curr.active) return acc
          return {
            fractional: acc.fractional + curr.price.fractional,
            currency: curr.price.currency,
          }
        },
        { fractional: 0, currency: 'EUR' as Currency },
      ),
    }))
    .filter(({ price }) => !!price.fractional)
}

const getTotalAmount = (ancillaries: AncillariesFormData): number =>
  getPriceInfo(ancillaries, { includeReservation: false }).reduce((acc, cur) => acc + cur.price.fractional, 0)

const getMaxCount = (passengers: PassengerData[], excludedType: string[]): number =>
  passengers.filter(item => !excludedType.includes(item.type)).length

const getLuggageDescription = (ancillary: Ancillary.Item): string =>
  ancillary.customAttributes
    .map(item => {
      switch (item.key) {
        case 'max_weight_in_kgs':
          return `${item.value} kg`
        case 'max_dimensions_in_cm':
          return `${item.value} cm`
        /* istanbul ignore next */
        default:
          return null
      }
    })
    /* in case we don't have such item.key we should filter out null value */
    .filter(item => item)
    .join(SEPARATOR)

const getCarbonDescription = (ancillary: Ancillary.Item): string | undefined =>
  ancillary.customAttributes.find(attr => attr.key === 'terms')?.value

const getVehicleMaxCount = (maxCount: number, ancillaries: Ancillary.Item[], code?: string): number => {
  const ancillariesCount = ancillaries.filter(item => item.code !== OFFLOADING_CODE).length
  const motorcycleCount = ancillaries.filter(item => item.code === MOTORCYCLE_CODE).length

  if (code === OFFLOADING_CODE) return ancillariesCount - motorcycleCount

  return maxCount === ancillariesCount ? 0 : maxCount
}

const getCarbonMaxCount = (maxCount: number, ancillaries: Ancillary.Item[]): number => ancillaries.length + maxCount

const getSegmentIndex = (ancillary: Ancillary.Item): number | null => {
  // ID is used as indicator to define if ancillary related to the booking level.
  // If it doesn't contain a number at the end it relates to the booking level (all segments)
  const index = Number(ancillary.id.at(-1))

  return isNaN(index) ? null : index
}

const getLevel = (ancillary: Ancillary.Item, carrier: string): 'booking' | 'pax' => {
  if (PAX_CATEGORY.includes(ancillary.category)) return 'pax'
  if (PAX_CARRIER_ANCILLARY.includes(carrier)) return 'pax'

  return 'booking'
}

const updateSegmentIndex = (ancillaries: Ancillary.Item[], carrier: string): Ancillary.Item[] =>
  ancillaries.map(item => {
    const segmentIndex = getSegmentIndex(item)
    const level = getLevel(item, carrier)
    if (segmentIndex == null) return { ...item, isAllSegments: true, level }
    if (SEGMENT_ANCILLARY.includes(item.category)) return { ...item, segmentIndex, level }

    return { ...item, level }
  })

const filterBySegment = (ancillaries: Ancillary.Item[], segmentsCount: number): Ancillary.Item[] =>
  ancillaries.filter((item, _, self) => {
    const count = self.filter(i => i.code === item.code).length

    return item.isAllSegments ?? count === segmentsCount
  })

const isOffloadingOver = (ancillaries: Ancillary.Item[]): boolean => {
  const transport = ancillaries.filter(item => ![OFFLOADING_CODE, MOTORCYCLE_CODE].includes(item.code))
  const offloading = ancillaries.filter(item => [OFFLOADING_CODE].includes(item.code))

  return offloading.length > transport.length
}

const getPriceMultiplier = (category: AncillaryCategory, multiplier: number): number => {
  const DEFAULT = 1

  return STATIC_PRICE_ANCILLARIES.includes(category) ? DEFAULT : multiplier
}

const getLimitation = (category: AncillaryCategory, connection: Connection): boolean | null => {
  const { marketingCarrier, departureTime } = connection

  const limitation = config.ancillaryLimitation[marketingCarrier.code]?.[category]

  if (!limitation) return null

  const shiftedDeparture = subHours(dateUtils.parse(departureTime, 'UTC'), limitation)

  return isAfter(shiftedDeparture, dateUtils.today())
}

const clearAttributes = (ancillary: Ancillary.Item): Ancillary.Item => ({
  ...ancillary,
  customAttributes: ancillary.customAttributes.map(attribute => ({ ...attribute, value: null as any })),
})

const getInitialFormData = (): AncillariesFormData => ({
  BICYCLE: [],
  LUGGAGE: [],
  PETS: [],
  EQUIPMENT: [],
  VEHICLE: [],
  ALERT: [],
  INSURANCE: [],
  ACCOMMODATION: [],
  MEAL: [],
  CARBON: [],
  HELP: [],
  SEAT: [],
  METRO: [],
  LEAD: [],
  BOARDING: [],
  LOUNGE: [],
  FASTTRACK: [],
})

const ancillaryUtils = {
  getPriceInfo,
  getTotalAmount,
  getByCategory,
  getBookingAncillariesParams,
  getMaxCount,
  getLuggageDescription,
  getVehicleMaxCount,
  getPaxAncillariesParams,
  updateSegmentIndex,
  getIcon,
  filterBySegment,
  isOffloadingOver,
  getInitialFormData,
  getCarbonMaxCount,
  getCarbonDescription,
  buildSeatAncillary,
  getCategoryFromCode,
  getPriceMultiplier,
  getLimitation,
  clearAttributes,
}
export default ancillaryUtils
